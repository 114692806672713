import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { select } from "d3"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { element } from "prop-types"

const SecondPage = () => {
  const [data, setData] = useState([
    {
      title: "FANG Companies",
      subCategory: "Revenue",
      data: [
        { value: 25, label: "Google" },
        { value: 50, label: "Amazon" },
        { value: 75, label: "Apple" },
        { value: 50, label: "Facebook" },
      ],
    },
  ])
  const svgRef = useRef()
  useEffect(() => {
    const svg = select(svgRef.current)
    console.log(svg)
    const dataCount = data[0].data.length
    svg
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 960 500")
      .selectAll("circle")
      .data(data[0].data)
      .join("circle")
      .attr("r", (element, i) => element.value)
      .attr("cx", (element, i) => {
        const svgWidth = parseInt(svg.style("width"))
        const center = svgWidth / dataCount
        const cx = center * (i + 1) - center / 2
        return cx
      })
      .attr("cy", element => {
        const svgHeight = parseInt(svg.style("height"))

        return svgHeight / 2
      })
      .style("fill", "hsla(195, 53%, 79%, 1)")
  }, [data])
  return (
    <Layout>
      <svg ref={svgRef}></svg>
    </Layout>
  )
}

export default SecondPage
